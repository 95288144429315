<template>
  <select 
    v-model="inputValue"
    @change="changeDataHandler"
  >
    <option value="">- เลือกประเภทสิ่งก่อสร้าง -</option>
    <option 
      v-for="(item, id) in type" 
      :key="id" 
      :value="id"
    >
      {{ item.name }}
    </option>
  </select>
</template>

<script>
/**
 * Building Type Dropdown
 * @description
 *    Display project type dropdown. 
 *    Data inside dropdown option come from JS Variable file)
 */

// load library and variables
import buildingType from '@/variables/project/buildingType.js';

export default {
  props: ['value'],
  data() {
    return {
      inputValue: '',
      type: {}
    }
  },

  created() {
    this.type = buildingType;
    this.inputValue = this.value;
  },

  methods: {
    /**
     * emit value to parent v-model
     */
    changeDataHandler () {
      this.$emit('input', this.inputValue);
    },
  }
}
</script>