<template>
  <input
    v-model="inputValue"
  />
</template>

<script>
/**
 * Budget Input
 * @description
 *    allow use enter number only and
 *    set number format with comma
 */

// load library and variables
import {numberInsertComma} from '@/utils/number/numberInsertComma.js';

export default {
  props: ['value'],
  data() {
    return {
      inputValue: 0,
    }
  },

  /**
   * pass parent v-model value to component data
   */
  created() {
    this.inputValue = this.value; //.toFixed(2)
  },

  /**
   * Add comma when enter number
   */
  watch: {
    inputValue: function (newValue) {
      this.inputValue = numberInsertComma(newValue);
      this.changeDataHandler();
    }
  },

  methods: {
    /**
     * emit value to parent v-model
     */
    changeDataHandler() {
      this.$emit('input', this.inputValue);
    },
  }
}
</script>